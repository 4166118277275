
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --background-green: #007f13;
  --radius: 5px;
}


main {
  min-height: 800px;
  background-color: var(--background-green);
}

#main-top {
  max-height: calc(4rem + 175px);
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4rem;
}

#main-top #tableau {
  min-width: calc(500px + 4rem);
  padding: 0;
}