.stock {
  
}

/* .stock .emptyCard {
    position:absolute;
} */

.stockCards {
    display: flex;
}

.wasteCard:nth-child(1) {
    position: relative;
}

.wasteCard:nth-child(2) {
    position: relative;
    /* left: -90px; */
}

.wasteCard:nth-child(3) {
    position: relative;
    /* left: -180px; */
}

.waste {
    
}

.wasteCard {
    top: 0px !important;
    margin-left: -90px;
}

.wasteCards {
    display: flex;
    max-width: 200px;
    margin-right: 2rem;
}

.wasteCards div {
    top: 0 !important;
}

.stock.card-wrapper {
    min-height: 176px;
}

