.card-wrapper {
    min-height: 175px;
    min-width: 120px;
    position: relative;
}

.card-wrapper.empty {
    border: 1px solid #8e8e8e;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.inner-circle {
    width: 50px;
    height: 50px;
    border: 3px solid rgba(14, 77, 13, 0.5);
    border-radius: 50%;
}